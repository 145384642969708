<template>
<iframe width="100%" height="100%" src="//www.youtube.com/embed/Y_kQ8DO3Wuw?loop=1&fs=0&playlist=Y_kQ8DO3Wuw&disablekb=1&controls=0&autoplay=1&iv_load_policy=3&modestbranding=1&origin=https://yves-tanas.onrender.com/&playsinline=1&showinfo=0" frameborder="0" allowfullscreen></iframe>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
}
</script>

<style scoped>
video {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
</style>
